import React, { Component } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import AOS from "aos"
import 'aos/dist/aos.css';
import NewsStrip from "../../components/news-strip"

class NewsPage extends Component {

  componentDidMount(){
    AOS.init();
    window.addEventListener('load', AOS.refresh);
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000);
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Tetley's provide transport for Chinese olympic team" description="Tetley's Coaches wins the Leeds City Council contract to provide transport for Olympic Teams training in Leeds." />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent page-article" style={{ background: 'white' }}>
            <div>
              <h1 className="page-content-heading">Tetley's provide transport for Chinese olympic team</h1>
              <p className="news-article-date">August 2012</p>
            </div>

            <div className="news-article-body">
              <p>Tetley's Coaches wins the Leeds City Council contract to provide transport for Olympic Teams training in Leeds.</p>
              <p>The Chinese Olympic team comprising athletes, training staff and management was housed at the University of Leeds and used training facilities throughout West Yorkshire.</p>
              <p>We provided transport from airports, to local training facilities and to the Olympic Park in Stratford, East London.</p>
            </div>
          </div>
        </div>

        <NewsStrip />

      </Layout>
    );
  }
}

export default NewsPage
